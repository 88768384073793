// GotoviProjekti.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import MDEditor from '@uiw/react-md-editor';
import { GET_PROJECT } from "../../api/queries/projects.js";
import "./gotoviprojekti.css";
import { useParams } from "react-router-dom";
import Header from "../../components/header/Header.js";

export default function GotoviProjekti() {
  const { id } = useParams(); 
  const { data: { getProjects: project } = {} } = useQuery(GET_PROJECT, {
    variables: { id } 
  });
  const [currentIndex, setCurrentIndex] = useState(0); // Dodajemo state za trenutni indeks slike
  
  if (!project) {
    return null; // Promijenili smo return statement na null umjesto praznog stringa
  }
  
  // Funkcije za navigaciju unaprijed i unatrag
  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? project.gallery.length - 1 : prevIndex - 1));
  };
  
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === project.gallery.length - 1 ? 0 : prevIndex + 1));
  };
  const opisNaSliciPodaciZaUsluge = {
    naslov: "",
    tekst:
      "",
    linkovi: [
      {
        imgSrc: "",
      },
    ],
  };
  return (
    <div className="gotoviProjektiContainer">
        <Header
        className="projektiPage"
        opisNaSliciPodaci={opisNaSliciPodaciZaUsluge}
      />
      <div className="gotoviProjektiDiv">
              <div className="gotoviProjektiHeader">
                <Link to="/projekti" className="gotoviProjektiA"><img src="/icons/strelicaLijevo.png"/>Nazad</Link>
              <h1 className="gotoviProjektiName">{project.name}</h1>
              <p></p>
              </div>
        <div className="slider">
          <button className="prevBtn" onClick={goToPrevSlide}>&#10094;</button>
          <button className="nextBtn" onClick={goToNextSlide}>&#10095;</button>
          <div className="slideWrapper">
            {project.gallery.map((img, index) => (
              <div className={index === currentIndex ? "slide active" : "slide"} key={index}>
                <img className="gotoviProjektiMainImg" src={img} alt={`Project`} />
              </div>
            ))}
          </div>
        </div>
          <MDEditor.Markdown
            source={project?.description}
            className="gotoviProjektiTkest"
          />
      </div>
    </div>
  );
}
