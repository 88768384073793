import "./footer.css";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { GlobalContext } from "../../context/global.js"
import { GET_PUBLIC_SETTINGS } from "../../api/queries/settings.js"
const Footer = () => {
  const { settings } = useContext(GlobalContext)

return(
  <footer className="footer">
    <img className="footerImg" src="/images/footer.png" alt="Footer" />
    <div className="footerDiv">
      <div className="footerLogo"></div>
      <div className="footerInfo">
        <div className="kontaktFooter">
          <h3>Kontakt</h3>
          {settings.contactPhone.map((p, index) => (
        <p className="email" key={index}>{p}</p>
    ))}
         
          
          {settings.contactEmail.map((a, index)=>(

          <a className="email" href="mailto:{a}" key={index} >{a}</a>))}
         
         
        </div>
        <div className="meniFoo">
          <h3>Meni</h3>
          {["Proizvodi", "Usluge", "Projekti", "O nama"].map((link, index) => (
            <Link key={index} to={`/${link.toLowerCase()}`}>
              {link}
            </Link>
          ))}
        </div>
        <div className="lokacijaFooter">
          <h3>Lokacija</h3>
          {[
            [
              "M18 Nikšićki put, Podgorica, Crna Gora",
              "https://maps.app.goo.gl/rsoCK88SeXZY5Q6h6",
            ],

          ].map(([text, link], index) => (
            <a key={index} href={link}>
              {text}
            </a>
          ))}
        </div>
        <div className="social-icons">
          {[
            {
              icon: "fb",
              link: "https://www.facebook.com/profile.php?id=100062950553808&mibextid=LQQJ4d",
            },
            {
              icon: "ig",
              link: "https://www.instagram.com/sbz_podgorica?igshid=MTNiYzNiMzkwZA==",
            },
          ].map((item, index) => (
            <a
              key={index}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="socialIcons"
                src={`/icons/${item.icon}.png`}
                alt={item.icon.toUpperCase()}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  </footer>
);
          }
export default Footer;
