import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import { BACKEND_HOST } from "../constants/index.js"

const link = new HttpLink({
  uri: `${BACKEND_HOST}/graphql`
})

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only"
    }
  }
});

export default client
