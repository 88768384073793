import React from "react";
import Usluge from "../../components/usluge/Usluge";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { opisNaSliciPodaci } from "../../components/header/Header";
import "./uslugePage.css";
import Iskopavanje from "../../components/iskopavanje/iskopavanje";
import ContactUs from "../../components/contactUs/ContactUs";

export function UslugePage() {
  // Ažurirajte objekt opisNaSliciPodaci s novom slikom
  const opisNaSliciPodaciZaUsluge = {
    naslov: "Svaki korak postaje izraz luksuza uz naše behaton rješenje",
    tekst:
      "Dobrodošli u svijet besprijekornog stila i trajne elegancije! Kroz našu stručnost i posvećenost, transformisat ćemo svaki prostor u remek-djelo, pružajući Vam prostor koji ne samo da izgleda izvanredno, već i traje dugi niz godina",
    linkovi: [
      {
        imgSrc: "/images/uslugePage.png", // Ažurirajte putanju s novom slikom
      },
    ],
  };

  return (
    <div className="uslugePage">
      {/* Proslijedite ažurirane podatke o slici */}
      <Header
        className="headerUslugePage"
        opisNaSliciPodaci={opisNaSliciPodaciZaUsluge}
      />
      <Iskopavanje />
      <ContactUs />
      <Footer />
    </div>
  );
}
