import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Pocetna } from "./pages/pocetna/Pocetna";
import { UslugePage } from "./pages/usluge/UslugePage";
import { Projekti } from "./pages/projekti/Projekti";
import { Onama } from "./pages/oNama/Onama";
import { Proizvodi } from "./pages/proizvodi/Proizvodi";
import GotoviProjekti from "./pages/gotoviProjekti/GotoviProjekti.js";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Pocetna />} />
        <Route exact path="/usluge" element={<UslugePage />} />
        <Route exact path="/proizvodi" element={<Proizvodi />} />
        <Route exact path="/projekti" element={<Projekti />} />
        <Route exact path="/o-nama" element={<Onama />} />
        <Route exact path="/projekat/:id" element={<GotoviProjekti/>} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
