import React, { useState } from "react";
import { useQuery } from "@apollo/client"

import { GET_CATEGORIES } from "../../api/queries/categories.js"
import { GET_ALL_PRODUCTS } from "../../api/queries/products.js"


import "./proizvodi.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

import ContactUs from "../../components/contactUs/ContactUs";

export function Proizvodi() {
  const [selectedItem, setSelectedItem] = useState({});

  const handleItemClick = (item) => {
    setSelectedItem({
      id: item?.id,
      name: item?.name
    });
  };

  const { data: { getAllCategories: categories } = {} } = useQuery(GET_CATEGORIES)

  const { data: { getAllProducts: products } = {} } = useQuery(GET_ALL_PRODUCTS, {
    variables: {
      payload: {
        categories: selectedItem?.id ? [selectedItem?.id] : undefined
      }
    }
  })


  const opisNaSliciPodaciZaUsluge = {
    naslov: "Betonska harmonija: elegancija i izdržljivost za dvorište.",
    linkovi: [
      {
        imgSrc: "/images/proizvodi.jpg",
      },
    ],
  };

  return (
    <>
      <Header opisNaSliciPodaci={opisNaSliciPodaciZaUsluge} />
      <div className="proizvodi">
        <div className="kategorije">
          <h3 className="naslovProizvod">Proizvodi</h3>

            <p
              className={`items ${
                !selectedItem?.id ? "selected" : ""
              }`}
              onClick={() => handleItemClick({ name: "Sve Kategorije" })}
            >
              Sve
            </p>

          {categories?.map((category, index) => (
            <p
              key={index}
              className={`items ${
                selectedItem?.id === category.id ? "selected" : ""
              }`}
              onClick={() => handleItemClick(category)}
            >
              {category.name}
            </p>
          ))}
        </div>
        <div>
          <p className="selectedP">{selectedItem?.name}</p>
          <div className="proizvodSlike">
            {products?.map((product, index) => (
              <div>
                <div className="podaciProizvoda">
                <p className="naslovProizvoda">{product.name} </p>
                
                </div>
                <img
                  className="filterImg"
                  key={index}
                  alt={""}
                  src={product.featuredImage}
                />
                 {product.gallery.map((img, index) => (
              <div className="" key={index}>
                <img className="filterImg" src={img} alt={`Project`} />
              </div>
            ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </>
  );
}
