import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import usluge from "./usluge.css";

export default function Usluge() {
  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const uslugeRef = useRef(null);
  const statistikaRef = useRef(null);
  const animationCompleted = useRef(false);
  const animatedDivRef = useRef(null);

  const handleScroll = () => {
    const statistikaTop = statistikaRef.current.offsetTop;
    const statistikaHeight = statistikaRef.current.clientHeight;

    const windowTop = window.scrollY;
    const windowBottom = windowTop + window.innerHeight;

    const isStatistikaInView =
      windowTop >= statistikaTop - window.innerHeight &&
      windowTop <= statistikaTop + statistikaHeight;

    if (isStatistikaInView && !animationCompleted.current) {
      animateCounter1();
      animateCounter2();
      animationCompleted.current = true;
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("inView");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (animatedDivRef.current) {
      observer.observe(animatedDivRef.current);
    }

    return () => {
      if (animatedDivRef.current) {
        observer.unobserve(animatedDivRef.current);
      }
    };
  }, []);

  const animateCounter = (targetNumber, duration, setCounter) => {
    const increment = (targetNumber / duration) * 16;
    let currentCounter = 0;

    const interval = setInterval(() => {
      currentCounter += increment;
      const roundedCounter = Math.min(currentCounter, targetNumber);
      setCounter(roundedCounter);

      if (roundedCounter >= targetNumber) {
        clearInterval(interval);
      }
    }, 16);
  };

  const animateCounter1 = () => {
    animateCounter(1000, 1000, setCounter1);
  };

  const animateCounter2 = () => {
    animateCounter(10, 1000, setCounter2);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="usluge" ref={animatedDivRef}>
        <div className="usluge1">
          <h1>Behaton: Praktičnost, stil, izdržljivost!</h1>
          <img className="primjerSlika" src="/images/behaton.png" />
          <img className="uskaSlika" src="/images/uskaSlika.png" />
        </div>

        <div className="usluge2">
          <h2>
            Ekskluzivna betonska galanterija: unikatne behaton ploče, ukrasne
            štanglice, kvarcni pijesak i mnogi drugi.
          </h2>
          <p className="uslugeP">
            SBZ predstavlja izuzetno bogat asortiman behaton ploča, ukrasnih
            štanglica, betonskih rešetki i kvarcnog pijeska. Naši proizvodi su
            vrhunskog kvaliteta, pružajući ne samo izdržljivost već i estetsku
            privlačnost. Otkrijte mogućnosti kombinovanja različitih vrsta ploča
            i stvarajte prostor iz snova.
          </p>
          <Link to="o-nama" className="viseOnama">
            <span>
              {" "}
              VIŠE O NAMA <img
                className="strelica"
                src="/icons/strelica.png"
              />{" "}
            </span>
          </Link>
          <br></br>
          <img className="ownImg" src="/images/ownimg.jpg" />
        </div>
      </div>
      <div className="statistika" ref={statistikaRef}>
        <div className="divPodaci">
          <img className="ikonice" src="/icons/uradjeno.png" />
          <div className="statsPodaci">
            <h1>{Math.floor(counter1)}+</h1>
            <p>ZAVRŠENI PROJEKTI</p>
          </div>
        </div>
        <div className="divPodaci">
          <img className="ikonice" src="/icons/group.png" />
          <div className="statsPodaci">
            <h1>{Math.floor(counter1)}+</h1>
            <p>ZADOVOLJNI KLIJENTI</p>
          </div>
        </div>
        <div className="divPodaci">
          <img className="ikonice" src="/icons/iskustvo.png" />
          <div className="statsPodaci">
            <h1>{Math.floor(counter2)}+</h1>
            <p>GODINE POSLOVANJA</p>
          </div>
        </div>
      </div>

      <div className="pavers">
        <img src="/images/pavers.jpg" className="imgPavers" />
        <div className="textPavers">
          <h1>Naša stručnost je vaš most od vizije do realizacije.</h1>
          <p>
            Izgradite budućnost s pouzdanim partnerom. Osigurajte uspjeh,
            postignuća i dugoročne rezultate uz naše stručno vođenje.
          </p>
          <Link onClick={() => window.scrollTo(0, 0)} to="/projekti">
            Projekti
          </Link>
        </div>
      </div>
      <div className="backImg">
        <h1 className="naslovTriDiva">
          Profesionalno postavljanje behatona – dugotrajno, estetski privlačno i
          kvalitetno izvedeno.
        </h1>
        <div className="triDiva">
          <div className="divUsluga">
            <Link
              className="pogledajDetalje"
              to="/usluge"
              onClick={() => window.scrollTo(0, 0)}
            >
              Pogledaj detalje
            </Link>
            <img className="ikoniceUsluge" src="/icons/bager.png" />
            <h3 className="usluzneInfo">Iskop i tamponiranje terena</h3>
            <p className="usluzniOpis">
              Iskoristite prednosti naše stručnosti u iskopavanju i tamponiranju
              terena kako biste stvorili optimalno okruženje za svoje
              građevinske poduhvate.
            </p>
          </div>
          <div className="divUsluga">
            <Link
              className="pogledajDetalje"
              to="/usluge"
              onClick={() => window.scrollTo(0, 0)}
            >
              Pogledaj detalje
            </Link>
            <img className="ikoniceUsluge" src="/icons/truck.png" />
            <h3 className="usluzneInfo">Dostavljanja materijala</h3>
            <p className="usluzniOpis">
              Dostavljamo materijale na vašu adresu - jednostavno, precizno i sa
              fokusom na vaše potrebe.
            </p>
          </div>
          <div className="divUsluga">
            <Link
              className="pogledajDetalje"
              to="/usluge"
              onClick={() => window.scrollTo(0, 0)}
            >
              Pogledaj detalje
            </Link>
            <img className="ikoniceUsluge" src="/icons/radnik.png" />
            <h3 className="usluzneInfo"> Postavljanje behatona i ivičnjaka</h3>
            <p className="usluzniOpis">
              Profesionalno postavljanje behatona i ivičnjaka za trajnu i
              estetski privlačnu urbanu okolinu.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
