import { gql } from "@apollo/client"

export const GET_PUBLIC_SETTINGS = gql`
  query GetSettings {
    getPublicSettings {
      contactPhone
      contactEmail
      workingHours
    }
  }
`
