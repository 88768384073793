import React from "react";

import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { opisNaSliciPodaci } from "../../components/header/Header";
import "./oNama.css";

export function Onama() {
  const opisNaSliciPodaciZaUsluge = {
    naslov: "SBZ betonska galanterija: elegancija i trajnost proizvoda",
    linkovi: [
      {
        imgSrc: "/images/oNama.jpg",
      },
    ],
  };

  return (
    <div className="oNama">
      {/* Proslijedite ažurirane podatke o slici */}
      <Header opisNaSliciPodaci={opisNaSliciPodaciZaUsluge} />
      <div className="teamContainer">
        <div className="divOpis">
          <div className="tekstOpis">
            <h1 className="naslovOnama">SBZ betonska galanterija </h1>
            <p>
              Betonska galanterija SBZ je renomirana kompanija koja se ističe po
              bogatom asortimanu visokokvalitetnih proizvoda. Firma je
              specijalizovana za proizvodnju behaton ploča od kvarca, prirodnog
              kamena, ukrasnih štanglica različitih modela, kao i betonskih
              rešetki. Osim toga, SBZ nudi i kvarcni pijesak, dodatni proizvod
              koji dopunjuje njihov širok spektar ponude.
            </p>
            <p>
              Behaton ploče izrađene od kvarca predstavljaju jedan od glavnih
              proizvoda ove firme. Kvarc je odabrani materijal zbog svoje
              izdržljivosti i estetskog izgleda, pružajući dugotrajnost i
              atraktivan izgled. Prirodni kamen, takođe prisutan u njihovoj
              ponudi, dodaje autentičnost i prirodni šarm proizvodima.
            </p>
            <p>
              Uz behaton ploče, SBZ nudi i ukrasne štanglice različitih modela,
              pružajući klijentima mogućnost da personalizuju svoje prostore
              prema vlastitim preferencijama. Betonske rešetke su još jedan
              proizvod koji doprinosi funkcionalnosti i estetici okoline, nudeći
              praktična rješenja za različite namjene.
            </p>
            <p>
              Ono što izdvaja Betonsku galanteriju SBZ od drugih je ne samo
              širok asortiman proizvoda već i mogućnost kombinovanja različitih
              vrsta ploča. Ova fleksibilnost omogućava klijentima da kreiraju
              jedinstvene i personalizovane dizajne koji odražavaju njihov stil
              i potrebe.
            </p>
            <p>
              Svi proizvodi ove firme su visokog kvaliteta, a estetski izgled
              ističe se po svojoj ljepoti. Bez obzira da li se radi o uređenju
              javnih prostora, dvorišta ili staza, Betonska galanterija SBZ
              pruža pouzdana rješenja koja spajaju funkcionalnost i estetiku.
              Osim toga, kvarcni pijesak koji nude dodatno upotpunjuje njihovu
              ponudu, čineći ih sveobuhvatnim dobavljačem proizvoda za uređenje
              eksterijera.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
