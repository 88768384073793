import { gql } from "@apollo/client"

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts($payload: SearchProductsPayload) {
    getAllProducts(payload: $payload) {
      id
      name
      description
      featuredImage
      gallery
      price
      createdAt
      featured
    }
  }
`

export const GET_PRODUCT = gql`
  query GetProduct($id: ID!) {
    getProducts(id: $id) {
      id
      name
      description
      featuredImage
      gallery
      price
      customHash
      createdAt
      featured
      categories {
        id
        name
        description
      }
    }
  }
`
