import { Link } from "react-router-dom";
import "./iskopavanje.css";
import React, { useEffect } from "react";

export default function Iskopavanje() {
  return (
    <div className="iskopavanje">
      <div className="iskop">
        <img className="imgIskop" src="/images/iskop.png" />

        <div className="opisIskop">
          <h1>Iskop i tamponiranje terena</h1>
          <p>
            Iskop i tamponiranje terena predstavljaju ključne faze zemljanih
            radova, od presudnog značaja za pripremu terena prije gradnje. Iskop
            podrazumijeva pažljivo uklanjanje zemlje prema preciznim dimenzijama
            dubine i širine, pružajući inženjerski odgovor na specifičnosti
            svakog projekta. Nudimo inovativna rješenja za građevinske izazove,
            prilagođena vašim potrebama. Izgradite temelje svoje budućnosti s
            nama - pouzdanim partnerom u graditeljstvu!
          </p>
          <div className="oznaceno">
            <p>
              <img src="/icons/oznaceno.png" /> Inicijacija i planiranje
            </p>
            <p>
              <img src="/icons/oznaceno.png" /> Terensko Istraživanje
            </p>
            <p>
              <img src="/icons/oznaceno.png" /> Priprema radne lokacije
            </p>
            <p>
              <img src="/icons/oznaceno.png" />
              Iskop terena
            </p>
            <p>
              <img src="/icons/oznaceno.png" />
              Transport i odlaganje materijala
            </p>
            <p>
              <img src="/icons/oznaceno.png" /> Tamponiranje terena
            </p>
          </div>
        </div>
      </div>
      <div className="iskop brDva">
        <div className="opisIskop">
          <h1>Postavljanje behatona i ivičnjaka</h1>
          <p>
            Transformirajte svoje okruženje s našom vrhunskom uslugom
            postavljanja behatona i ivičnjaka! Nudimo precizno oblikovanje
            prostora, stvarajući trajne i estetski privlačne površine koje
            odražavaju Vaš stil. Naš stručni tim kombinira inovaciju i kvalitet,
            pružajući ne samo funkcionalnost, već i vizuelnu privlačnost.
            Učinite Vašu okolinu jedinstvenom - birajte najbolje za
            besprijekoran spoj elegancije i izdržljivosti!
          </p>
          <div className="oznaceno">
            <p>
              <img className="tacno" src="/icons/oznaceno.png" /> Ocjenjivanje
              Lokacije
            </p>
            <p>
              <img className="tacno" src="/icons/oznaceno.png" /> Dizajn i
              planiranje
            </p>
            <p>
              <img className="tacno" src="/icons/oznaceno.png" /> Izbor
              materijala
            </p>
            <p>
              <img className="tacno" src="/icons/oznaceno.png" /> Spajanje
              materijala
            </p>
            <p>
              <img className="tacno" src="/icons/oznaceno.png" /> Završno
              dotjerivanje
            </p>
            <p>
              <img className="tacno" src="/icons/oznaceno.png" /> Održavanje i
              Zaštita
            </p>
          </div>
        </div>
        <img className="imgIskop" src="/images/postavljanje.png" />
      </div>
    </div>
  );
}
