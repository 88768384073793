import React from "react";
import Usluge from "../../components/usluge/Usluge";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { opisNaSliciPodaci } from "../../components/header/Header";
import { Helmet } from 'react-helmet';
import ContactUs from "../../components/contactUs/ContactUs";
export function Pocetna() {
  return (
    <>
     <Helmet>
       
        <meta name="description" content="Betonska galanterija SBZ je renomirana kompanija koja se ističe po bogatom asortimanu visokokvalitetnih proizvoda. " />
      </Helmet>
  
      <Header opisNaSliciPodaci={opisNaSliciPodaci} />
      <Usluge />
      <ContactUs />
      <Footer />
    </>
  );
}
