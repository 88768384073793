import React from "react";

import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./projektiPage.css";
import ZavrseniProjekti from "../../components/zavrseniProjekti/ZavrseniProjekti";
import ContactUs from "../../components/contactUs/ContactUs";

export function Projekti() {
  // Ažurirajte objekt opisNaSliciPodaci s novom slikom
  const opisNaSliciPodaciZaUsluge = {
    naslov: "Elegancija u praksi: Pogledajte naše uspješno završene projekte!",
    tekst:
      "U našem portfoliu dominira behaton, čije smo estetske i funkcionalne prednosti uspješno integrirali u različite projektne kontekste. Svaki projekt je priča za sebe, ispričana kroz pažljivo odabrane boje, oblike i teksture behatona.",
    linkovi: [
      {
        imgSrc: "/images/projekatBckg.png", // Ažurirajte putanju s novom slikom
      },
    ],
  };

  return (
    <div className="projektiPage">
      {/* Proslijedite ažurirane podatke o slici */}
      <Header
        className="projektiPage"
        opisNaSliciPodaci={opisNaSliciPodaciZaUsluge}
      />
      <ZavrseniProjekti />
      <ContactUs />
      <Footer />
    </div>
  );
}
