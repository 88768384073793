import { gql } from "@apollo/client"

export const GET_ALL_PROJECTS = gql`
  query GetAllProjects {
    getAllProjects {
      id
      name
      description
      featuredImage
      gallery
      createdAt
      featured
    }
  }
`

export const GET_PROJECT = gql`
  query GetProject($id: ID!) {
    getProjects(id: $id) {
      id
      name
      description
      featuredImage
      gallery
      customHash
      createdAt
      featured
    }
  }
`
