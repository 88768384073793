import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client"
import MDEditor from '@uiw/react-md-editor';

import { GET_ALL_PROJECTS } from "../../api/queries/projects.js"

import "./zavrseniProjekti.css";

export default function ZavrseniProjekti() {
  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }
  const { data: { getAllProjects: projects } = {} } = useQuery(GET_ALL_PROJECTS)
  return (
    <div>
      <h1 className="naslovZavrsenihProjekata">
        Pogledajte uspješno završene projekte
      </h1>
      <div className="zavrseniProjekti">
        <div className="projektiKartice">
          {projects?.map((project, index) => (
            <div className="projekatDiv" key={index}>
              <img
                className="projektiSlike"
                src={project.featuredImage}
                alt={`Project`}
              />
              <p className="projektiNaslov">{project.name}</p>

              <MDEditor.Markdown
  source={truncateText(project?.description, 150)}
  style={{ whiteSpace: 'pre-wrap' }}
  className="projektiTekst"
/>

              <Link className="seeMoreProjekat" to={`/projekat/${project.id}`}>Pogledaj više</Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
