import { Link } from "react-router-dom";
import React, { useState } from "react";
import "./header.css";
import { useRef } from "react";
export const opisNaSliciPodaci = {
  naslov: "Elegancija i izdržljivost u Vašem koraku!",
  tekst:
    "Učinite prostor iznimno elegantnim i trajnim! Širok izbor boja i uzoraka pretvara svaki korak u umjetničko djelo.",
  linkovi: [
    {
      tekst: "KONTAKTIRAJ",
      to: "/#kontakt",

      klasa: "kontaktiraj",
      imgSrc: "/images/bckg.jpg",
    },
    { tekst: "USLUGE", klasa: "uslugeBtn", to: "/usluge" },
  ],
};

export default function Header({ opisNaSliciPodaci }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const linkoviSaKlasom = {
    pocetna: {
      tekst: "Početna",
      klasa: "to",
      putanja: "/",
      imgSrc: "/images/bckg.jpg",
      class: "link",
    },
    proizvodi: {
      tekst: "Proizvodi",
      klasa: "to",
      putanja: "/proizvodi",
      imgSrc: "/images/bckg.jpg",
      class: "link",
    },
    usluge: {
      tekst: "Usluge",
      klasa: "to",
      putanja: "/usluge",
      imgSrc: "/images/bckg.jpg",
      class: "link",
    },
    projekti: {
      tekst: "Projekti",
      klasa: "to",
      putanja: "/projekti",
      imgSrc: "/images/bckg.jpg",
      class: "link",
    },
    oNama: {
      tekst: "O nama",
      klasa: "to",
      putanja: "/o-nama",
      imgSrc: "/images/bckg.jpg",

      class: "link",
    },
    kontakt: {
      tekst: "Kontakt",
      klasa: "to",
      putanja: "/#kontakt",
      class: "link kontraktirajNas",
    },
  };
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="header">
      <div className="bgImg">
        <img
          src={opisNaSliciPodaci.linkovi[0].imgSrc}
          alt="peoples"
          className="naslovna"
        />
      </div>
      <div className="navbarLink">
        <div className={`dropMenu ${isMenuOpen ? "open" : ""}`}>
          {Object.keys(linkoviSaKlasom)
            .filter((key) => key !== "pocetna") // Izostaviti "pocetna" link
            .map((key, index) => (
              <Link
                key={index}
                to={linkoviSaKlasom[key].putanja}
                className={linkoviSaKlasom[key].class}
              >
                {linkoviSaKlasom[key].tekst}
              </Link>
            ))}
        </div>

        <div className="logoDiv">
          <Link to="/">
            <img className="logo" src="/images/sbzLogo.png" alt="Logo" />
          </Link>
        </div>

        <img className="meni" src="/icons/menu.png" onClick={handleMenuClick} />

        <div className="navbar">
          {Object.keys(linkoviSaKlasom).map((key, index) => (
            <Link
              key={index}
              to={linkoviSaKlasom[key].putanja}
              className={linkoviSaKlasom[key].class}
            >
              {linkoviSaKlasom[key].tekst}
            </Link>
          ))}
        </div>
      </div>
      <div className="opisNaSlici">
        <h1>{opisNaSliciPodaci.naslov}</h1>
        <p>{opisNaSliciPodaci.tekst}</p>
        {opisNaSliciPodaci.linkovi.map((link, index) => (
          <Link to={link.to} key={index} className={link.klasa}>
            {link.tekst}
          </Link>
        ))}
      </div>
    </div>
  );
}
