import { useState, useContext } from "react"
import { useLazyQuery } from "@apollo/client"

import { Link } from "react-router-dom";

import { GlobalContext } from "../../context/global.js"

import { CONTACT_US } from "../../api/queries/contactus.js"

import notify from "../../utils/notify.js"

import "./contactUs.css";

export default function ContactUs(offsetTop) {
  const [payload, setPayload] = useState({})

  const { settings } = useContext(GlobalContext)

  // Evo ti ovde mozes console log da vidis sta imas u settings
 //console.log(settings)

  const changePayload = (key, value) => setPayload(prevState => ({ ...prevState, [key]: value }))

  const [contactUs, { loading }] = useLazyQuery(CONTACT_US, {
    onCompleted: d => {
      if(d?.contactUs) {
        notify("success", "Hvala Vam što ste nas kontaktirali")

        setPayload({})
      } else {
        notify("error", "Došlo je do greške")
      }
    },
    onError: e => {
      notify("error", "Došlo je do greške")
    }
  })

  const sendMessage = async () => {
    if(["email", "subject", "message"].some(key => !payload[key])) {
      notify("info", "Molimo Vas popunite sva obavezna polja")
      return;
    }

    contactUs({
      variables: {
        payload
      }
    })
  }

  return (
    <div className="contactUs" id="kontakt">
      <div className="dvaDiva">
        <img className="imgContact" src="images/contactUs.png" />
        <div className="contactDvaDiva">
          <div id="kontaktDiv1" className="kontaktDiv1">
            <h2>Kontaktirajte nas za više informacija!</h2>
            <input
              className="input"
              type="email"
              placeholder="Vaša email adresa"
              value={payload.email || ""}
              onChange={e => changePayload("email", e.target.value)}
              required
            ></input>
            <input className="input" placeholder="Naslov" value={payload.subject || ""} onChange={e => changePayload("subject", e.target.value)} required></input>
            <textarea placeholder="Vaša poruka" value={payload.message || ""} onChange={e => changePayload("message", e.target.value)} required></textarea>
            <button className="posalji" onClick={sendMessage}>Pošalji</button>
          </div>
          <div className="kontaktDiv2">
            <h2>Naša lokacija!</h2>
            <div>
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11777.131397982894!2d19.2142061!3d42.4429976!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134deb4e480cfba5%3A0x134040161193872a!2sBetonska%20Galanterija%20SBZ%20Podgorica!5e0!3m2!1sbs!2s!4v1701954355551!5m2!1sbs!2s"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="infoContact">
          <div className="info prvi">
            <span className="vrijeme">
              {" "}
              <img className="ikoniceContact" src="/icons/call.png" />
              <h3>POZOVITE NAS</h3>
            </span>
            {settings.contactPhone.map((p, index) => (
              <p key={index}>
               {p}
              </p>
            ))}
          </div>
          <div className="info drugi">
            <span className="vrijeme loc">
              <img className="ikoniceContact " src="/icons/location.png" />
              <h3>LOKACIJA</h3>
            </span>
            <a
              className="lokacija"
              href="https://maps.app.goo.gl/rsoCK88SeXZY5Q6h6"
            >
              M18 Nikšićki put, Podgorica, Crna Gora
            </a>
            <br />
            <a
              className="lokacija"
              href="https://www.google.com/maps/place/Betonska+Galanterija+SBZ+Bijelo+Polje/@43.0445772,19.7668022,17z/data=!3m1!4b1!4m6!3m5!1s0x1352bb0046c108a1:0x1d9421e7f7049106!8m2!3d43.0445733!4d19.7693771!16s%2Fg%2F11vjwhwkyc?hl=bs-ME&entry=ttu"
            >
              Industrijska bb, Bijelo Polje 84000, Crna Gora
            </a>
          </div>
          <div className="info treci">
            <span className="vrijeme">
              <img className="ikoniceContact" src="/icons/vrijemee.png" />
              <h3>RADNO VRIJEME</h3>
            </span>
            <p>{settings.workingHours}</p>
          </div>
        </div>
      </div>

      <div className="zastoMi">
        <div className="div1">
          <p className="pZasto">ZAŠTO MI?</p>
          <h1 className="kreiramo">
            Kreiramo inovativna rješenja za Vašu ekspanziju
          </h1>
          <p className="unaprijedite">
            Unaprijedite vaše poslovanje uz našu stručnost. Kreiramo inovativna
            rješenja prilagođena vašoj ekspanziji, postavljajući temelje za
            održivi uspjeh i globalni uticaj.
          </p>
        </div>

        <div className="div2">
          <div className="support">
            <div>
              <img className="ikoniceSupp" src="/icons/vrijeme.png" />
            </div>
            <div>
              <h1>Brzo gradimo</h1>{" "}
              <p>
                Sa našim ekspeditivnim timom za gradnju, projekti su gotovi u
                rekordnom vremenu. Brza izvedba, bez kompromisa na kvalitetu.
              </p>
            </div>
          </div>
          <div className="support">
            <div>
              <img className="ikoniceSupp" src="/icons/profesionalnost.png" />
            </div>
            <div>
              <h1>Profesionalnost</h1>
              <p>
                Gradimo s pažnjom prema detaljima i visokim standardima
                profesionalizma.
              </p>
            </div>
          </div>
          <div className="support">
            <div>
              <img className="ikoniceSupp" src="/icons/support.png" />
            </div>
            <div>
              <h1>24/7 Podrška</h1>
              <p>
                Naša neprestana podrška dostupna 24/7 osigurava da smo uvijek uz
                vas, pružajući hitne odgovore i rješenja kad god ih trebate.
              </p>
            </div>
          </div>
        </div>
        <div className="div3">
          <div className="imgSupport">
            <img className="strelicaPos" src="/icons/strelicaa.png" />
            <img className="support1" src="/images/support1.png" />
            <img className="support2" src="/images/support2.png" />
          </div>
        </div>
      </div>
    </div>
  );
}
