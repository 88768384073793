import { gql } from "@apollo/client"

export const GET_CATEGORIES = gql`
  query GetAllCategories {
    getAllCategories {
      id
      name
      description
    }
  }
`
