import { useState, useEffect, createContext } from "react"
import { useQuery } from "@apollo/client"

import { GET_PUBLIC_SETTINGS } from "../api/queries/settings.js"

const GlobalContext = createContext()

const GlobalProvider = ({ children }) => {
  const [state, setState] = useState({
    sidebarOpened: false,
  })

  const { data: { getPublicSettings: settings } = {} } = useQuery(GET_PUBLIC_SETTINGS)

  return (
    <GlobalContext.Provider
      value={{
        state,
        setState,
        settings: {
          ...(settings || {}),
          contactPhone: settings?.contactPhone?.split(",") || [],
          contactEmail: settings?.contactEmail?.split(",") || [],
        }
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalProvider }
